<script>
	import { onMount, onDestroy } from "svelte";
	import { dbroundTimer, dbroundTimerStarted, listenOnFirebaseKey } from "../services/database";
	import { playSound, preLoadGifs } from "../services/utils";
	import { otherUsersStore } from "../services/store";
	import GameTitle from "../components/GameTitle.svelte";
	import HostPicked from "../components/HostPicked.svelte";
	import LobbyBox from "../components/LobbyBox.svelte";
	import BaseButton from "../components/BaseButton.svelte";
	import GameThemes from "../components/GameThemes/index.svelte";
	import ControlBar from "../components/controlBar/index.svelte";
	import Card1 from "../components/svg/Card1.svelte";
	import Card2 from "../components/svg/Card2.svelte";
	import Card3 from "../components/svg/Card3.svelte";
	import Rule from "../components/roundRules/index.svelte";
	import { amIHost } from "../services/hostStore";

	//Brought Back themes

	let timer;
	let showLobby = false;
	let disabled = true;

	let otherUsers = [];
	let onlineUsers;

	let timerLobby;
	let noOfPeopleNeed;

	let text = "Start Game";
	let isArrow = true;
	let starting = false;
	let expiryAt, expiryTimer, time;

	$: otherUsers = $otherUsersStore;

	$: onlineUsers = otherUsers.filter(user => user.online);

	$: {
		if (onlineUsers) {
			noOfPeopleNeed = 4 - 1 - onlineUsers.length;
			if ($amIHost && onlineUsers.length > 2) disabled = false;
			else disabled = true;
		}
	}

	$: if (time && time === 4) {
		playSound("INTROSTOP");
		playSound("THREESEC");
	}

	let startRound = () => {
		starting = true;
		isArrow = false;
		text = "Starting...";
		dbroundTimerStarted().set(true);
	};

	onMount(() => {
		timer = setTimeout(() => {
			showLobby = true;
		}, 3000);
	});

	onDestroy(() => {
		clearTimeout(timer);
		playSound("THREESECSTOP");
	});

	onMount(() => {
		listenOnFirebaseKey(dbroundTimerStarted(), val => (timerLobby = val));
		listenOnFirebaseKey(dbroundTimer(), val => {
			expiryAt = val;
			time = Math.round((expiryAt - new Date().getTime()) / 1000);
			expiryTimer = setInterval(() => {
				time = Math.round((expiryAt - new Date().getTime()) / 1000);
			}, 1000);
		});
		preLoadGifs("round rules");
	});
	onDestroy(() => {
		clearInterval(expiryTimer);
	});
</script>

<div class="lobbyScreen">
	<div class="lobbyContainer">
		{#if timerLobby && time <= 4}
			<div class="GameTitle"><GameTitle /></div>
			<div class="cardBox">
				{#if time == 2}
					<div class="showCards"><Card1 /></div>
				{:else if time == 3}
					<div class="showCards"><Card2 /></div>
				{:else if time == 4}
					<div class="showCards"><Card3 /></div>
				{/if}
			</div>
		{:else if showLobby}
			<div class="GameTitle">
				<div class="controlbar"><ControlBar showInfoIcon={false} /></div>
				<GameTitle />
				<div class="themes"><GameThemes /></div>
			</div>
			<div class="lobbyBody"><LobbyBox /></div>
			<div class="lobbyBtn">
				{#if !disabled || starting}
					<BaseButton {text} {isArrow} disabled={starting} btnHandler={startRound} />
				{:else if !onlineUsers}
					<span class="text">Need 4 more players to start the game</span>
				{:else if onlineUsers?.length < 3}
					<span class="text">Need {noOfPeopleNeed} more players to start the game</span>
				{:else}
					<span class="text">Host will start the game soon..</span>
				{/if}
			</div>
		{:else}
			<div class="GameTitle"><GameTitle /></div>
			<div class="body"><HostPicked /></div>
		{/if}
	</div>
	{#if timerLobby && time <= 14 && time > 5}
		<div class="rules">
			<Rule {time} />
		</div>
	{/if}
</div>

<style>
	.lobbyScreen {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
	}
	.lobbyContainer {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		position: absolute;
		z-index: 3;
	}
	.rules {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		z-index: 4;
		background-color: rgba(0, 0, 0, 0.5);
	}
	.lobbyBody {
		width: 100%;
		height: 70%;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 16px;
	}
	.lobbyBtn {
		width: 100%;
		height: 15%;
		display: flex;
		justify-content: center;
		align-items: flex-start;
	}
	.GameTitle {
		width: 100%;
		height: 15%;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		position: relative;
	}
	.themes {
		position: absolute;
		right: 24px;
	}
	.controlbar {
		position: absolute;
		left: 24px;
	}
	.body {
		width: 100%;
		height: 85%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.cardBox {
		width: 100%;
		height: 85%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.showCards {
		width: 20%;
		height: 62.5%;
		max-width: 266px;
		max-height: 394px;
	}
	.text {
		font-family: Manrope;
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
		line-height: 27px;
		text-align: center;
		color: #ffffff;
		opacity: 0.5;
	}
	@media screen and (max-width: 780px) {
		.showCards {
			min-width: 174px;
			min-height: 263px;
		}
		.text {
			font-size: 16px;
			line-height: 20px;
		}
	}

	@media screen and (max-width: 640px) {
		.themes {
			display: none;
		}
		.controlbar {
			left: 16px;
		}
	}
</style>
