<script>
	import { onMount, onDestroy } from "svelte";
	import { user } from "../services/database";
	import { playSound } from "../services/utils.ts";
	import CardTop from "./CardTop.svelte";

	export let chooseQuestion = () => {};
	export let itemsDisplayed;

	let showCardBack = false;
	let time;
	let selectedCard = null;
	let customQuestion = {
		tdi_id: user.id,
		value: "",
	};

	$: if (selectedCard) {
		let question;
		if (itemsDisplayed[0].tdi_id === selectedCard) question = itemsDisplayed[0];
		else if (itemsDisplayed[1].tdi_id === selectedCard) question = itemsDisplayed[1];
		else if (customQuestion.tdi_id === selectedCard) question = customQuestion;
		chooseQuestion(question);
	}

	let onChange = value => {
		customQuestion = { ...customQuestion, value };
	};

	let cardClick = () => playSound("CLICK");

	let HandlerForCustomQuestion = tdi_id => {
		selectedCard = tdi_id;
		document.getElementById("inputArea").focus();
	};

	onMount(() => {
		time = setTimeout(() => {
			showCardBack = true;
		}, 500);
	});
	onMount(() => {
		playSound("SHUFFLE");
	});
	onDestroy(() => {
		clearTimeout(time);
	});
</script>

<div class="cardPickerBox">
	<div class="textBox">
		<span class="text">Select or Create a fun prompt to vote the "Most Likely to" team member!</span>
	</div>
	<div class="cardPickerBoxContainer">
		{#each itemsDisplayed as item}
			<div
				class="flip-box"
				on:mousedown={cardClick}
				on:click={() => (selectedCard = item.tdi_id)}
				class:highlight={selectedCard && selectedCard == item.tdi_id ? true : false}
				class:lowlight={selectedCard != null && selectedCard != item.tdi_id ? true : false}
			>
				<div class="flip-box-inner" class:flip-it={showCardBack}>
					<CardTop {item} {showCardBack} />
				</div>
			</div>
		{/each}
		<div
			class="flip-box"
			on:mousedown={cardClick}
			on:click={HandlerForCustomQuestion(customQuestion.tdi_id)}
			class:highlight={selectedCard && selectedCard == customQuestion.tdi_id ? true : false}
			class:lowlight={selectedCard != null && selectedCard != customQuestion.tdi_id ? true : false}
		>
			<div class="flip-box-inner" class:flip-it={showCardBack}>
				<CardTop {onChange} />
			</div>
		</div>
	</div>
</div>

<style>
	.cardPickerBox {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.flip-box {
		background-color: transparent;
		width: 222px;
		height: 329px;
		cursor: pointer;
	}
	.flip-box-inner {
		position: relative;
		width: 222px;
		height: 329px;
		text-align: center;
		transition: transform 0.6s;
		transform-style: preserve-3d;
	}
	.flip-it {
		transform: rotateY(180deg);
	}
	.textBox {
		width: 100%;
		height: 20%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.text {
		font-family: manrope;
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		line-height: px;
		letter-spacing: 0.7px;
		text-align: center;
		color: #ffffff;
		margin-bottom: 8px;
	}
	.cardPickerBoxContainer {
		width: 90%;
		height: 80%;
		max-width: 750px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.highlight {
		width: 232px;
		height: 339px;
		border: 5px solid #7866e7;
		border-radius: 8px;
		filter: drop-shadow(0px 0px 30px #150c4e);
	}
	.lowlight {
		opacity: 0.5;
	}
	/* changed */
	@media screen and (max-width: 780px), (max-height: 520px) {
		.cardPickerBox {
			margin-bottom: 5px;
		}
		.textBox {
			height: 10%;
		}
		.text {
			font-size: 18px;
			line-height: 18px;
		}
		.cardPickerBoxContainer {
			width: 80%;
			height: 90%;
			max-width: 450px;
		}
		.flip-box {
			width: 133px;
			height: 197px;
		}
		.flip-box-inner {
			width: 133px;
			height: 197px;
		}
		.highlight {
			width: 143px;
			height: 207px;
		}
	}
</style>
