<script>
	import { onMount } from "svelte";
	import { getParams, preLoadGifs } from "../services/utils";
	import {
		dbRoundQuestions,
		dbRoundPickedQuestions,
		dbResult,
		dbResultRound,
		dbShowInfo,
		listenOnFirebaseKey,
	} from "../services/database";

	import GameTitle from "../components/GameTitle.svelte";
	import RoundInfo from "../components/RoundInfo.svelte";
	import NextButtonBox from "../components/NextButtonBox.svelte";
	import ResultBody from "../components/ResultBody.svelte";
	import GameTime from "../components/image/GameTime.svelte";
	import ControlBar from "../components/controlBar/index.svelte";
	import { amIHost } from "../services/hostStore";

	let allTheQuestions;
	let allThePickedQuestions;
	let result;
	let resultRound;
	let cardData = {};
	let showInfo = false;

	$: if (allTheQuestions && allThePickedQuestions && resultRound >= 0 && resultRound < 5) {
		let { tdi_id, value } = allThePickedQuestions[resultRound];
		cardData.question = value;
		let askedBy = [];
		for (const [key, value] of Object.entries(allTheQuestions)) {
			if (value.tdi_id == tdi_id) {
				askedBy = [...askedBy, key];
			}
		}
		cardData.askedBy = askedBy;
	}

	onMount(() => {
		listenOnFirebaseKey(dbRoundQuestions(), val => (allTheQuestions = val));
		listenOnFirebaseKey(dbRoundPickedQuestions(), val => (allThePickedQuestions = val));
		listenOnFirebaseKey(dbResult(), val => (result = val));
		listenOnFirebaseKey(dbResultRound(), val => (resultRound = val));
		preLoadGifs("gameOver");
		let userId = getParams("userId");
		dbShowInfo()
			.child(`${userId}`)
			.once("value")
			.then(value => {
				if (!value.exists()) {
					showInfo = true;
					setTimeout(() => {
						dbShowInfo().child(`${userId}`).set(true);
						showInfo = false;
					}, 3000);
				} else {
					showInfo = false;
				}
			});
	});
</script>

<div class="container">
	<img class="bgSvg2" src="/assets/svg/thumbs.svg" alt="thumbs svg" />
	<img class="bgSvg1" src="/assets/svg/result.svg" alt="result svg" />
	{#if showInfo}
		<div class="info">
			<div class="gifBox">
				<GameTime />
				<span class="text">The game is about stories, so don’t forget to spill some beans 😂</span>
			</div>
		</div>
	{/if}
	<div class="resultContainer">
		<div class="GameTitle">
			<div class="controlbar"><ControlBar showInfoIcon={false} /></div>
			<GameTitle />
			<div class="roundInfo">
				<RoundInfo />
			</div>
		</div>
		<div class="resultDisplay">
			{#if cardData && result}
				<ResultBody {cardData} {result} {resultRound} />
			{/if}
		</div>
		<div class="buttontoNext">
			{#if $amIHost}
				<NextButtonBox />
			{/if}
		</div>
	</div>
</div>

<style>
	.container {
		width: 100%;
		height: 100%;
		background: #030303;
	}
	.resultContainer {
		width: 100%;
		height: 100%;
		font-family: Manrope;
		position: absolute;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		z-index: 3;
	}
	.bgSvg1 {
		width: 100%;
		height: 100%;
		position: absolute;
		z-index: 1;
	}
	.bgSvg2 {
		width: 100%;
		height: 100%;
		position: absolute;
		z-index: 2;
	}
	.info {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		z-index: 4;
		background-color: rgba(0, 0, 0, 0.5);
	}
	.gifBox {
		width: auto;
		height: auto;
		padding: 16px;
		background: #ffffff;
		border-radius: 8px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.text {
		width: 360px;
		font-family: manrope;
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		line-height: 26px;
		text-align: center;
		color: #36474f;
	}
	.GameTitle {
		width: 100%;
		height: 15%;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		position: relative;
	}
	.controlbar {
		position: absolute;
		left: 48px;
	}
	.roundInfo {
		position: absolute;
		right: 48px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.resultDisplay {
		width: 80%;
		height: 75%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.buttontoNext {
		width: 80%;
		height: 10%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	@media screen and (max-width: 1100px), (max-height: 600px) {
		.resultDisplay,
		.buttontoNext {
			width: 90%;
		}
	}
	@media screen and (max-width: 780px), (max-height: 520px) {
		.buttontoNext {
			height: 15%;
			width: 80%;
		}

		.resultDisplay {
			width: 80%;
		}
	}
</style>
