<script>
	import { dbHost, listenOnFirebaseKey } from "../services/database";
	import { myUserStore, otherUsersStore } from "../services/store";
	import PlayerListing from "./PlayerListing.svelte";

	let otherUsers = [];
	let myUser;
	$: otherUsers = $otherUsersStore;

	$: myUser = $myUserStore;
</script>

<div class="lobbyBox">
	<div class="textBox"><span class="text">Waiting for others to join...</span></div>
	<div class="lobbyBoxcontainer">
		<ul class="list">
			{#if myUser}
				<li><PlayerListing user={myUser} /></li>
			{/if}
			{#each otherUsers as user}
				<li><PlayerListing {user} /></li>
			{/each}
		</ul>
	</div>
</div>

<style>
	.lobbyBox {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.textBox {
		width: 100%;
		height: 20%;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items: center;
	}
	.text {
		font-family: manrope;
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		line-height: 20px;
		text-align: center;
		color: #ffffff;
		margin-bottom: 16px;
	}
	.lobbyBoxcontainer {
		width: 100%;
		height: 80%;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
	}
	.list {
		width: 100%;
		height: 100%;
		max-width: 374px;
		max-height: 366px;
		background: #ffffff;
		border-radius: 15px;
		overflow-x: hidden;
		overflow-y: auto;
		padding: 0;
		margin: 0;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		list-style-type: none;
		padding-bottom: 24px;
	}

	.list::-webkit-scrollbar {
		width: 8px;
		height: 122px;
	}

	.list::-webkit-scrollbar-thumb {
		background: #2a337e;
		border-radius: 15px;
	}

	.list::-webkit-scrollbar-track {
		background: transparent;
		margin-right: 10px;
	}

	.list::-webkit-scrollbar-track-piece:end {
		background: transparent;
		margin-bottom: 10px;
	}

	.list::-webkit-scrollbar-track-piece:start {
		background: transparent;
		margin-top: 10px;
	}

	.list li {
		width: 100%;
		height: 25px;
		margin-top: 24px;
		padding: 0 20px 0 20px;
	}

	@media screen and (max-width: 780px) {
		.list {
			height: 175px;
		}
		.text {
			font-size: 18px;
			line-height: 18px;
			margin-bottom: 12px;
		}
	}
</style>
