<script>
	import TooltipAvatar from "./Avatar.svelte";
	import { clickOutside } from "./helper";

	export let AllOnline;
	export let allHostIds;
	let isClicked = false;
	let x;
	let y;
	let w, h;

	let distance;

	$: if (w <= 780 || h <= 520) {
		distance = 200;
	} else {
		distance = 250;
	}

	function mouseOver(event) {
		isClicked = true;
		x = event.pageX - distance;
		y = event.pageY + 5;
	}
	function mouseLeave() {
		isClicked = false;
	}
</script>

<svelte:window bind:innerWidth={w} bind:innerHeight={h} />

<div use:clickOutside on:click_outside={mouseLeave} on:click={mouseOver} on:focus style="cursor: pointer;">
	<slot />
</div>

{#if isClicked}
	<div style="top: {y}px; left: {x}px;" class="tooltip">
		{#each AllOnline as voter}
			<div class="container">
				<TooltipAvatar userName={voter.userName} src={voter.profilePicture} />
				<div class="name">
					<span class="text">{voter.userName}</span>
				</div>
				<div class="host">
					{#if $allHostIds.includes(voter.id)}
						<span class="text2">(Host)</span>
					{/if}
				</div>
			</div>
		{/each}
	</div>
{/if}

<style>
	.tooltip {
		width: 250px;
		max-height: 189px;
		background: #ffffff;
		box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.25);
		border-radius: 8px;
		position: fixed;
		z-index: 15;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: 16px 16px 8px 16px;
		overflow-y: auto;
		overflow-x: hidden;
	}
	.container {
		width: 100%;
		height: 25px;
		display: flex;
		justify-content: space-between;
		margin-bottom: 8px;
	}
	.name {
		width: calc(100% - 95px);
		height: 25px;
		margin-left: 10px;
		margin-right: 10px;
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}
	.text {
		font-family: Manrope;
		font-style: normal;
		font-weight: 600;
		font-size: 14px;
		line-height: 16px;
		color: #36474f;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
	.host {
		height: 25px;
		width: 50px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.text2 {
		font-family: Manrope;
		font-style: normal;
		font-weight: 600;
		font-size: 14px;
		line-height: 16px;
		color: #36474f;
	}
	::-webkit-scrollbar {
		display: none;
	}
	@media screen and (max-width: 780px), (max-height: 520px) {
		.tooltip {
			width: 200px;
			max-height: 150px;
			padding: 8px 8px 4px 8px;
		}
		.container {
			height: 20px;
			margin-bottom: 4px;
		}
		.name {
			width: calc(100% - 85px);
			height: 20px;
			margin-left: 6px;
		}
		.text {
			font-size: 12px;
			line-height: 14px;
		}
		.host {
			height: 20px;
			width: 45px;
		}
		.text2 {
			font-size: 12px;
			line-height: 14px;
		}
	}
</style>
