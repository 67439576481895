<script>
	import { fade, fly } from "svelte/transition";
	import AskedBy from "./AskedBy.svelte";

	export let resultRound;
	export let cardData;
</script>

{#if cardData.question != undefined}
	<div class="cardBoxContainer">
		<div class="card">
			<div class="container">
				<div class="cardHeader" />
				<div class="title">
					<span class="red">WHO’S</span><span class="black">MOST LIKELY TO...</span>
				</div>
				<div class="cardContent">
					{#key cardData.question}
						<span class="question" in:fly={{ x: 50, duration: 1750 }}>...{cardData.question}</span>
					{/key}
				</div>
				<div class="semiFooter">
					<span class="by">Question by:</span>
					<div class="askedBy"><AskedBy askedBy={cardData?.askedBy} /></div>
				</div>
				<div class="cardFooter">
					<div class="bar">
						{#each Array(5) as item, i}
							{#if i === resultRound}
								<img class="visitingSvg" src="/assets/svg/visiting.svg" alt="visiting" />
							{:else if i < resultRound}
								<img class="visitedSvg" src="/assets/svg/visited.svg" alt="visited" />
							{:else if i > resultRound}
								<img class="visitNextSvg" src="/assets/svg/visitNext.svg" alt="visitNext" />
							{/if}
						{/each}
					</div>
				</div>
			</div>
		</div>
	</div>
{/if}

<style>
	.cardBoxContainer {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: flex-start;
	}
	.card {
		width: 85%;
		height: 100%;
		border-radius: 10px;
		background: #ffffff;
	}
	.container {
		width: 100%;
		height: 100%;
		position: relative;
	}
	.cardHeader {
		width: 100%;
		height: 21px;
		max-height: 21px;
		position: absolute;
		background: #d23a47;
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
		z-index: 10;
	}
	.semiFooter {
		width: 100%;
		height: 32px;
		max-height: 32px;
		position: absolute;
		bottom: 45px;
		border-bottom-left-radius: 10px;
		border-bottom-right-radius: 10px;
		z-index: 10;
		display: flex;
		padding-left: 10px;
		justify-content: flex-start;
		align-items: center;
	}
	.by {
		font-family: Manrope;
		font-style: normal;
		font-weight: bold;
		font-size: 12px;
		line-height: 118%;
		color: rgba(18, 18, 18, 0.5);
		margin-right: 24px;
		display: flex;
		align-items: center;
	}
	.cardFooter {
		width: 100%;
		height: 21px;
		max-height: 21px;
		position: absolute;
		bottom: 0;
		border-bottom-left-radius: 10px;
		border-bottom-right-radius: 10px;
		z-index: 10;
		display: flex;
		justify-content: center;
		align-items: flex-start;
	}
	.title {
		width: 100%;
		height: 38px;
		margin-top: 40px;
		position: absolute;
		z-index: 11;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}
	.red {
		width: 100%;
		height: 14px;
		font-family: MODULAR-B;
		font-style: normal;
		font-weight: normal;
		font-size: 13.9831px;
		line-height: 13px;
		color: #d23a47;
		padding-left: 10px;
		text-align: left;
	}
	.black {
		width: 100%;
		height: 23px;
		font-family: MODULAR-B;
		font-style: normal;
		font-weight: normal;
		font-size: 23.3212px;
		line-height: 19px;
		color: #000000;
		padding-left: 10px;
		text-align: left;
	}
	.cardContent {
		width: 100%;
		height: 100%;
		border-bottom-left-radius: 10px;
		border-bottom-right-radius: 10px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.question {
		width: 100%;
		height: fit-content;
		font-family: manrope;
		font-style: normal;
		font-weight: bold;
		font-size: 23.5921px;
		line-height: 118%;
		padding: 0 10px 0 10px;
		color: #121212;
		text-align: left;
		overflow-wrap: break-word;
	}
	.bar {
		width: 90px;
		display: flex;
		justify-content: space-between;
	}
	.visitingSvg {
		width: 17px;
		height: 10px;
	}
	.visitedSvg,
	.visitNextSvg {
		width: 10px;
		height: 10px;
	}
	@media screen and (max-width: 1100px), (max-height: 600px) {
		.question {
			font-size: 16px;
			padding-top: 10px;
		}
	}
	@media screen and (max-width: 780px), (max-height: 520px) {
		.card {
			width: 80%;
			height: 100%;
		}
		.cardHeader {
			height: 15px;
			max-height: 15px;
		}
		.title {
			height: 21px;
			margin-top: 25px;
		}
		.red {
			height: 12px;
			font-size: 12px;
			line-height: 12px;
		}
		.black {
			height: 16px;
			font-size: 16px;
			line-height: 16px;
		}
		.question {
			font-size: 12px;
			padding-top: 10px;
		}

		.semiFooter {
			height: 21px;
			max-height: 21px;
			bottom: 30px;
		}

		.cardFooter {
			height: 15px;
			max-height: 15px;
		}
		.visitingSvg {
			width: 14px;
			height: 8px;
		}
		.visitedSvg,
		.visitNextSvg {
			width: 8px;
			height: 8px;
		}
		.by {
			margin-right: 16px;
		}
	}
</style>
