<script>
	import { ProfilePicture } from "da-components";
	import { allHostIds } from "../services/hostStore";
	export let user;
	let state;
	$: {
		if (user) {
			state = user.connecting ? "connecting" : user.online ? "online" : "offline";
		}
	}
</script>

{#if user.userName && state}
	<div class="listing">
		<div class="infoBox">
			<ProfilePicture userName={user?.userName} src={user?.profilePicture} size="25" fontSize="12" />
			<div class="name">
				<span
					>{user.userName}
					{#if $allHostIds.includes(user.id)}
						(Host)
					{/if}</span
				>
			</div>
		</div>
		<div class="status">
			{#if state === "online"}
				<img src="/assets/svg/connected.svg" alt="online" />
			{:else if state === "connecting"}
				<img src="/assets/svg/connecting.svg" alt="connecting" />
			{:else if state === "offline"}
				<img src="/assets/svg/disconnected.svg" alt="offline" />
			{/if}
		</div>
	</div>
{/if}

<style>
	.listing {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: space-between;
	}
	.infoBox {
		width: auto;
		height: 25px;
		display: flex;
		justify-content: flex-start;
	}
	.name {
		font-family: Manrope;
		font-weight: 700;
		font-style: normal;
		font-size: 16px;
		line-height: 13px;
		color: #0c0030;
		display: flex;
		align-items: center;
		margin-left: 10px;
	}
	.status {
		width: 25px;
		height: 25px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	img {
		width: 18px;
		height: 18px;
	}
</style>
